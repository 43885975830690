import React from 'react';

const LongevityPrivacyPage = () => {
  return (
    <div style={Styles.container}>
        <h1 style={Styles.title}>Privacy Policy for Longevity Checklist</h1>
        <p style={Styles.text}>
        Last updated: October 27, 2024
        </p>
        <br />
        <br />
        <h3 style={Styles.subTitle}>Information We Collect</h3>
        <p style={Styles.text}>
            Longevity Checklist does not currently collect any data.
        </p>
        {/* <h3 style={Styles.subTitle}>Information We Collect</h3>
        <p style={Styles.text}>
        We collect the following information from our users:
        Name: We collect your name to personalize your experience and communicate with you effectively.
        Email: Your email address is required for account-related notifications and to send you updates.
        Payment Information: We collect payment information solely for processing your orders securely. Rest assured that we prioritize your payment data's security.
        Non-Personal Data: We use web cookies to gather non-personal information about your browsing preferences. These cookies help enhance your user experience and analyze website traffic.
        </p> */}
        {/* <h3 style={Styles.subTitle}>Purpose of Data Collection</h3>
        <p style={Styles.text}>
        We collect and use your data for the following purposes:
        Order Processing: Your name and payment information are essential for processing orders you make through our platform.
        Account Login: We use your email and name for account creation and login purposes.
        </p> */}
        {/* <h3 style={Styles.subTitle}>Data Sharing</h3>
        <p style={Styles.text}>
        We want to make it clear: we do not share your data with any third parties. Your information is kept confidential and is used exclusively for the purposes mentioned in this policy.
        </p> */}
        <h3 style={Styles.subTitle}>Your rights</h3>
        <p style={Styles.text}>
            Our Site may, from time to time, contain links to and from the websites of our partner networks, advertisers and
            affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or
            liability for these policies. Please check these policies before you submit any personal data to these websites.
        </p>
        <h3 style={Styles.subTitle}>Updates to the Privacy Policy</h3>
        <p style={Styles.text}>
            We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes we may make to our
            privacy policy in the future will be posted on this page.
        </p>
        <h3 style={Styles.subTitle}>Disclaimer</h3>
        <p style={Styles.text}>
        Longevity Checklist provides general information and guidance to promote healthy living. It is not a substitute for professional medical advice, diagnosis, or treatment. Always consult with a qualified healthcare provider for personalized medical advice.
        </p>
        {/* <h3 style={Styles.subTitle}>Contact Information</h3>
        <p style={Styles.text}>
        If you have any questions or concerns about our Privacy Policy or how we handle your data, please feel free to contact us at michael@taci.uk
        Thank you for trusting us with your information. We are committed to protecting your privacy and helping you explore your curiosities while keeping your data safe and secure.
        </p> */}
    </div>
  );
};

const Styles = {
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        backgroundColor: '#ebfbff',
        minHeight: '100vh',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    subTitle: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    text: {
        fontSize: 16,
        width: '60%',
        minWidth: 300,
        textAlign: 'center',
    },
};

export default LongevityPrivacyPage;