import React from 'react';

const PrivacyPage = () => {
  return (
    <div style={Styles.container}>
        <h1 style={Styles.title}>Privacy Policy for Buzzin</h1>
        <p style={Styles.text}>
        Last updated: September 22, 2024
        </p>
        <br />
        <br />
        <h3 style={Styles.subTitle}>Information We Collect</h3>
        <p style={Styles.text}>
        Buzzin does not currently collect any data.
        </p>
        {/* <h3 style={Styles.subTitle}>Information We Collect</h3>
        <p style={Styles.text}>
        We collect the following information from our users:
        Name: We collect your name to personalize your experience and communicate with you effectively.
        Email: Your email address is required for account-related notifications and to send you updates.
        Payment Information: We collect payment information solely for processing your orders securely. Rest assured that we prioritize your payment data's security.
        Non-Personal Data: We use web cookies to gather non-personal information about your browsing preferences. These cookies help enhance your user experience and analyze website traffic.
        </p> */}
        {/* <h3 style={Styles.subTitle}>Purpose of Data Collection</h3>
        <p style={Styles.text}>
        We collect and use your data for the following purposes:
        Order Processing: Your name and payment information are essential for processing orders you make through our platform.
        Account Login: We use your email and name for account creation and login purposes.
        </p> */}
        {/* <h3 style={Styles.subTitle}>Data Sharing</h3>
        <p style={Styles.text}>
        We want to make it clear: we do not share your data with any third parties. Your information is kept confidential and is used exclusively for the purposes mentioned in this policy.
        </p> */}
        <h3 style={Styles.subTitle}>Children's Privacy</h3>
        <p style={Styles.text}>
        Buzzin does not collect any data from children. Our services are intended for individuals who are at least 18 years old or the legal age of consent in their jurisdiction.
        </p>
        <h3 style={Styles.subTitle}>Your rights</h3>
        <p style={Styles.text}>
        Our Site may, from time to time, contain links to and from the websites of our partner networks, advertisers and
        affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or
        liability for these policies. Please check these policies before you submit any personal data to these websites.
        </p>
        <h3 style={Styles.subTitle}>Updates to the Privacy Policy</h3>
        <p style={Styles.text}>
        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes we may make to our
        privacy policy in the future will be posted on this page.
        </p>
        {/* <h3 style={Styles.subTitle}>Contact Information</h3>
        <p style={Styles.text}>
        If you have any questions or concerns about our Privacy Policy or how we handle your data, please feel free to contact us at michael@taci.uk
        Thank you for trusting us with your information. We are committed to protecting your privacy and helping you explore your curiosities while keeping your data safe and secure.
        </p> */}
    </div>
  );
};

const Styles = {
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        backgroundImage: 'url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cdefs%3E%3CradialGradient%20id%3D%22grad%22%20cx%3D%2250%25%22%20cy%3D%2250%25%22%20r%3D%2250%25%22%20fx%3D%2250%25%22%20fy%3D%2250%25%22%3E%3Cstop%20offset%3D%220%25%22%20stop-color%3D%22%23e8f0a0%22%20stop-opacity%3D%221%22%20/%3E%3Cstop%20offset%3D%22100%25%22%20stop-color%3D%22%23d5e841%22%20stop-opacity%3D%221%22%20/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect%20x%3D%220%22%20y%3D%220%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22url(%23grad)%22%20/%3E%3C/svg%3E")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    subTitle: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    text: {
        fontSize: 16,
        width: '60%',
        minWidth: 300,
        textAlign: 'center',
    },
};

export default PrivacyPage;