import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import BuzzinPage from './BuzzinPage';
import PrivacyPage from './PrivacyPage';
import TypesetterPage from './TypesetterPage';
import TypesetterPrivacyPage from './TypesetterPrivacyPage';
import LongevityPage from './LongevityPage';
import LongevityPrivacyPage from './LongevityPrivacyPage';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/buzzin" element={<BuzzinPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/typesetter" element={<TypesetterPage />} />
        <Route path="/typesetter-privacy" element={<TypesetterPrivacyPage />} />
        <Route path="/longevitychecklist" element={<LongevityPage />} />
        <Route path="/longevitychecklist-privacy" element={<LongevityPrivacyPage />} />
      </Routes>
    </Router>
  );
}
export default App;
