import React from 'react';
import longevityChecklistLogo from './images/LongevityLogo.png';
//import appStoreButton from './images/downloadAppStore.svg';

const LongevityPage = () => {
  return (
    <div style={styles.containerStyle}>
      <svg style={styles.backgroundSvgStyle}>
        <defs>
          <radialGradient id="grad" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" stopColor="white" stopOpacity="1" />
            <stop offset="100%" stopColor="#ade8f4" stopOpacity="1" />
          </radialGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
      </svg>
      <div style={styles.contentStyle}>
        <div style={styles.header}>
          <img
            src={longevityChecklistLogo}
            alt="Longevity Checklist Logo"
            style={styles.logoImage}
          />
          <h1 style={styles.headingStyle}>Longevity Checklist</h1>
        </div>
        <p style={styles.paragraphStyle}>
        Harness the latest scientific breakthroughs in the emerging field of longevity to extend your lifespan with Longevity Checklist. Our app guides you on a personalized journey toward a healthier lifestyle, providing daily tasks and long-term goals designed to promote healthy habits and support your longevity journey.
        </p>
        <h3 style={styles.subTitle}>Features</h3>
        <p style={styles.paragraphStyle}>
        Daily Tasks: Receive curated daily habits and activities that encourage health and well-being. From nutrition tips to exercise suggestions, we've got you covered.
        </p>
        <p style={styles.paragraphStyle}>
        Long-Term Goals: Set and track objectives to foster lasting lifestyle changes. Achieve milestones that contribute to a healthier future.
        </p>
        <p style={styles.paragraphStyle}>
        Personalized Recommendations: Tailor your checklist based on your preferences and needs. The app adapts to you, ensuring a unique experience.
        </p>
        <br />
        <p style={styles.paragraphStyle}>
        Start Your Longevity Journey Today!
        </p>
        {/* <div style={styles.appStoreButtonContainer}>
          <a
            href="https://apps.apple.com/ca/app/typesetter-book-notes/id6736673526"
            style={styles.appStoreButton}
          >
            <img
              src={appStoreButton}
              alt="Download on the App Store"
              style={styles.appStoreButtonImage}
            />
          </a>
        </div> */}
      </div>
    </div>
  );
};

const styles = {
  containerStyle: {
    position: 'relative',
    minHeight: '100vh',
    overflow: 'hidden',
    alignContent: 'center',
  },
  backgroundSvgStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  contentStyle: {
    position: 'relative',
    zIndex: 1,
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    paddingBottom: '20vh',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  logoImage: {
    width: '100px',
    height: '100px',
    marginRight: '20px',
  },
  subTitle: {
    fontSize: '1.5em',
    fontWeight: 'bold',
  },

  appStoreButtonContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  appStoreButton: {
    display: 'inline-block',
    overflow: 'hidden',
    borderRadius: '13px',
    width: '250px',
    height: '83px',
  },
  appStoreButtonImage: {
    borderRadius: '13px',
    width: '162px',
    height: '54px',
  },
  headingStyle: {
    fontSize: '2em',
  },
  paragraphStyle: {
    textAlign: 'justify',
  },
};

export default LongevityPage;
