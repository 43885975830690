import React from 'react';
import typesetterLogo from './images/typesetter.png';
import appStoreButton from './images/downloadAppStore.svg';

const TypesetterPage = () => {
  return (
    <div style={styles.containerStyle}>
      <svg style={styles.backgroundSvgStyle}>
        <defs>
          <radialGradient id="grad" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" stopColor="#fff6d2" stopOpacity="1" />
            <stop offset="100%" stopColor="#eee2b1" stopOpacity="1" />
          </radialGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
      </svg>
      <div style={styles.contentStyle}>
        <div style={styles.header}>
          <img
            src={typesetterLogo}
            alt="Buzzin Logo"
            style={styles.logoImage}
          />
          <h1 style={styles.headingStyle}>Typesetter: Book Notes</h1>
        </div>
        <p style={styles.paragraphStyle}>
          Start by the adding books you want to read in the future to your Bookshelf.
        </p>
        <p style={styles.paragraphStyle}>
          Then move the books to the currently reading section where you can record important take aways and facts as you read.
        </p>
        <p style={styles.paragraphStyle}>
          Finally move them to the Notes tab where all your past books with their notes can be reviewed.
        </p>
        <div style={styles.appStoreButtonContainer}>
          <a
            href="https://apps.apple.com/ca/app/typesetter-book-notes/id6736673526"
            style={styles.appStoreButton}
          >
            <img
              src={appStoreButton}
              alt="Download on the App Store"
              style={styles.appStoreButtonImage}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  containerStyle: {
    position: 'relative',
    minHeight: '100vh',
    overflow: 'hidden',
    alignContent: 'center',
  },
  backgroundSvgStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  contentStyle: {
    position: 'relative',
    zIndex: 1,
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    paddingBottom: '20vh',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  logoImage: {
    width: '100px',
    height: '100px',
    marginRight: '20px',
  },

  appStoreButtonContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  appStoreButton: {
    display: 'inline-block',
    overflow: 'hidden',
    borderRadius: '13px',
    width: '250px',
    height: '83px',
  },
  appStoreButtonImage: {
    borderRadius: '13px',
    width: '162px',
    height: '54px',
  },
  headingStyle: {
    fontSize: '2em',
  },
  paragraphStyle: {
    textAlign: 'justify',
  },
};

export default TypesetterPage;