import React from 'react';
import buzzinLogo from './images/buzzinLogo.png';
import appStoreButton from './images/downloadAppStore.svg';

const BuzzinPage = () => {
  return (
    <div style={styles.containerStyle}>
      <svg style={styles.backgroundSvgStyle}>
        <defs>
          <radialGradient id="grad" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" stopColor="#e8f0a0" stopOpacity="1" />
            <stop offset="100%" stopColor="#d5e841" stopOpacity="1" />
          </radialGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
      </svg>
      <div style={styles.contentStyle}>
        <div style={styles.header}>
          <img
            src={buzzinLogo}
            alt="Buzzin Logo"
            style={styles.logoImage}
          />
          <h1 style={styles.headingStyle}>Buzzin: Party Game</h1>
        </div>
        <p style={styles.paragraphStyle}>
          Buzzin is an exciting and easy to play party game that will keep you and your friends entertained for hours!
        </p>
        <p style={styles.paragraphStyle}>
          To play, enter all your friends' names and then simply start the game. Follow the instructions on each card. Cards can involve one or multiple players and include classic games like "Would You Rather" and "Never Have I Ever," plus many other surprises!
        </p>
        <p style={styles.paragraphStyle}>
          Whether you're at the bar or hanging out at home, Buzzin will energize the night and get everyone laughing.
        </p>
        <div style={styles.appStoreButtonContainer}>
          <a
            href="https://apps.apple.com/ca/app/buzzin-party-game/id6657960077"
            style={styles.appStoreButton}
          >
            <img
              src={appStoreButton}
              alt="Download on the App Store"
              style={styles.appStoreButtonImage}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  containerStyle: {
    position: 'relative',
    minHeight: '100vh',
    overflow: 'hidden',
    alignContent: 'center',
  },
  backgroundSvgStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  contentStyle: {
    position: 'relative',
    zIndex: 1,
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    paddingBottom: '20vh',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  logoImage: {
    width: '100px',
    height: '100px',
    marginRight: '20px',
  },

  appStoreButtonContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  appStoreButton: {
    display: 'inline-block',
    overflow: 'hidden',
    borderRadius: '13px',
    width: '250px',
    height: '83px',
  },
  appStoreButtonImage: {
    borderRadius: '13px',
    width: '162px',
    height: '54px',
  },
  headingStyle: {
    fontSize: '2em',
  },
  paragraphStyle: {
    textAlign: 'justify',
  },
};

export default BuzzinPage;