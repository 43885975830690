import React, { useEffect, useRef } from 'react';
import 'particles.js';

const ParticlesBackground = () => {
  const particlesContainer = useRef(null);

  useEffect(() => {
    if (!particlesContainer.current) return;

    const particlesConfig = [
      {
        "particles": {
          "number": {
            "value": 25,
            "density": {
              "enable": true,
              "value_area": 800
            }
          },
          "color": {
            "value": "#000000"
          },
          "shape": {
            "type": "circle",
            "stroke": {
              "width": 0,
              "color": "#000000"
            },
            "polygon": {
              "nb_sides": 5
            },
            "image": {
              "src": "img/github.svg",
              "width": 100,
              "height": 100
            }
          },
          "opacity": {
            "value": 1,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": 4,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 40,
              "size_min": 0.1,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 300,
            "color": "#555",
            "opacity": 0.4,
            "width": 2
          },
          "move": {
            "enable": true,
            "speed": 4, // Speed of particles
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": false,
              "mode": "repulse"
            },
            "onclick": {
              "enable": true,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "grab": {
              "distance": 800,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 800,
              "size": 80,
              "duration": 2,
              "opacity": 0.8,
              "speed": 3
            },
            "repulse": {
              "distance": 400,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        },
        "retina_detect": true
      }
    ];

    const initializeParticles = () => {
      if (particlesContainer.current && window.particlesJS) {
        window.particlesJS(particlesContainer.current.id, particlesConfig[0]);
      }
    };

    // Ensure the DOM is ready
    if (document.readyState === 'complete') {
      initializeParticles();
    } else {
      window.addEventListener('load', initializeParticles);
    }

    // Cleanup function
    return () => {
      window.removeEventListener('load', initializeParticles);
      if (window.pJSDom && window.pJSDom.length > 0) {
        window.pJSDom[0].pJS.fn.vendors.destroypJS();
        window.pJSDom = [];
      }
    };
  }, []);

  return <div id="particles-js" ref={particlesContainer} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />;
};

export default ParticlesBackground;